<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <div class="d-flex">
          <feather-icon
            icon="ArrowLeftIcon"
            size="24"
            class="align-middle text-primary mr-2 mb-2 back"
            @click="$router.go(-1)"
          />
          <h3>Detail Mitra</h3>
        </div>
        <b-row>
          <b-col
            cols="12"
            xl="9"
            lg="8"
            md="7"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="21"
                  xl="6"
                  class="d-flex justify-content-start"
                >
                  <b-avatar
                    :src="userData.fotoProfil"
                    :text="avatarText(userData.nama)"
                    variant="light-primary"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ userData.nama }}
                      </h4>
                      <div class="mt-50">
                        <b-badge
                          :variant="saldo < 20000 ? 'light-danger' : 'light-primary'"
                        >
                          <small class="mr-25 text-secondary">Saldo</small>
                          {{ saldo }}
                        </b-badge>
                      </div>
                      <b-badge
                        class="text-capitalize mt-1"
                        :variant="statusMitra().variant"
                      >
                        {{ statusMitra().display }}
                      </b-badge>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                      <th>
                        <feather-icon
                          icon="PhoneIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Nomor HP</span>
                      </th>
                      <td>
                        {{ userData.no_hp }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="UserIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Email</span>
                      </th>
                      <td>
                        {{ userData.emailValue }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="ClockIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Waktu Daftar</span>
                      </th>
                      <td>
                        {{ getDate(userData.waktu_daftar) }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="CircleIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Jenis Kelamin</span>
                      </th>
                      <td>
                        {{ userData.jenis_kelamin === 0 ? 'Laki Laki' : 'Perempuan' }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="SendIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Status Order</span>
                      </th>
                      <td>{{ userData.status_order == true ? 'Orderan On' : 'Orderan Off' }}</td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="MapPinIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Kota</span>
                      </th>
                      <td>{{ userData.kota }}</td>
                    </tr>
                  </table>
                  <div class="mt-1">
                    <b-badge
                      v-for="layanan in userData.layanan"
                      :key="layanan.vertikal"
                      class="badge-mitra text-capitalize"
                      variant="primary"
                    >
                      {{ vertikalMitra(layanan.vertikal) }} {{ areaMitra(layanan.area) }}
                    </b-badge>
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="userData.status_aktif === 3">
              <h3>
                <feather-icon
                  icon="AlertCircleIcon"
                  class="text-danger"
                  size="28"
                />
                Data Mitra Ditolak
              </h3>
              <div v-if="userData.alasan_ditolak">
                <p>Data mitra ini ditolak karena alasan : </p>
                <h5 class="font-weight-bolder text-warning">
                  {{ userData.alasan_ditolak }}
                </h5>
                <small>Update terakhir : {{ getDate(userData.terakhir_diedit) }}</small>
              </div>
            </b-card>
          </b-col>
          <b-col
            v-if="permission.mitraUpdate || permission.mitraDelete || permission.mitraBlock"
            cols="12"
            md="5"
            xl="3"
            lg="4"
          >
            <b-card>
              <b-button
                v-if="userData.status_aktif !== 4 && permission.mitraBlock"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="aktifkanMitra"
              >
                Aktifkan
              </b-button>
              <b-button
                v-if="userData.status_aktif < 4 && permission.mitraBlock"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                block
                @click="tolakMitra"
              >
                Tolak Pendaftaran
              </b-button>
              <hr v-if="userData.status_aktif < 4 || userData.status_aktif !== 4">
              <b-button
                v-if="permission.mitraUpdate"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="mb-1"
                block
                @click="ubahNomorHp()"
              >
                Ubah Nomor HP
              </b-button>
              <router-link
                v-if="permission.mitraUpdate"
                :to="{ name: 'EditMitra', params: userData ? {id: userData.no_hp} : {id: '123'} }"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  block
                >
                  Ubah Data
                </b-button>
              </router-link>
              <b-dropdown
                v-if="permission.mitraDelete"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                text="Blokir"
                block
                variant="outline-warning"
                class="my-1"
              >
                <b-dropdown-item
                  class="dropdown-blokir"
                  @click="tolakMitra"
                >
                  Tolak data mitra
                </b-dropdown-item>
                <b-dropdown-item
                  class="dropdown-blokir"
                  @click="saldoKurang()"
                >
                  Saldo Kurang
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="status in blokirOptions"
                  :key="status.value"
                  class="dropdown-blokir"
                  @click="blokirMitra(status)"
                >
                  {{ status.label }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-if="permission.mitraDelete"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                block
                @click="hapusMitra"
              >
                Hapus Data
              </b-button>
              <b-button
                v-if="permission.mitraDelete"
                variant="outline-primary"
                class="my-1"
                block
                @click="$router.push({ name: 'history-payment', params: {id} })"
              >
                Riwayat Transaksi
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-card
          v-if="permission.mitraUpdate"
          title="Dokumen Mitra"
        >
          <swiper
            class="swiper-responsive-breakpoints"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(data,index) in swiperData"
              :key="index"
            >
              <b-card
                v-if="data.img"
                :img-src="data.img"
                img-alt="Card image"
                img-top
                class="dokumen-card"
                img-height="500"
                @click="showPreview(data.img)"
              >
                <b-card-text class="text-center">
                  {{ data.text }}
                </b-card-text>
              </b-card>
              <b-card
                v-else
                style="height: 500px"
                border-variant="danger"
              >
                <div class="d-flex flex-column justify-content-center h-100">
                  <div class="text-center">
                    <feather-icon
                      icon="XIcon"
                      size="48"
                      class="text-danger"
                    />
                    <br>
                    <span class="text-danger">{{ data.text }} belum diupload</span>
                  </div>
                </div>
              </b-card>
            </swiper-slide>
            <div
              slot="pagination"
              class="swiper-pagination"
            />
          </swiper>
        </b-card>

        <!-- Maps location mitra -->
        <b-card title="Lokasi Mitra">
          <l-map
            :zoom="zoom"
            :center="center"
          >
            <l-tile-layer
              :url="url"
            />
            <l-marker
              :lat-lng="mitraPosition"
            >
              <l-popup>
                <div class="d-flex">
                  <b-avatar
                    class="mr-1"
                    :src="userData.fotoProfil"
                    size="45"
                  />
                  <div>
                    <h6>{{ userData.nama }}</h6>
                    <small>{{ userData.no_hp }}</small>
                  </div>
                </div>
              </l-popup>
            </l-marker>
          </l-map>
        </b-card>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { showImages } from 'vue-img-viewr'
import 'vue-img-viewr/styles/index.css'
import firebase from 'firebase/app'
import { db } from '@/firebase'
import permission from '@/permission'
import regionJson from '@/db/region.json'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

// for maps mitra

/* eslint-disable arrow-body-style */
/* eslint-disable radix */
export default {
  directives: {
    Ripple,
  },
  components: {
    Swiper,
    SwiperSlide,
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSpinner,

    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    FeatherIcon,
  },
  props: ['id'],
  data() {
    return {
      keterangan: '',
      saldo: 0,
      permission: permission.access,
      loading: true,
      userData: [],
      region: regionJson,
      swiperData: [],
      previewData: [],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
      blokirOptions: [
        { value: '6', label: 'Harus Konseling' },
        { value: '7', label: 'Perlu Retraining' },
        { value: '8', label: 'Akun tidak aktif' },
        { value: '9', label: 'Blokir semua akses' },
      ],

      // for Maps
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [],
      mitraPosition: [],
    }
  },
  created() {
    this.getMitra()
  },
  methods: {
    showPreview(url) {
      const imgIndex = this.previewData.indexOf(url)
      showImages({
        urls: this.previewData,
        index: imgIndex,
      })
    },
    getDate(unix) {
      const timestamp = unix
      const dateObject = new Date(timestamp)

      return dateObject.toLocaleString('id-ID', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
    getMitra() {
      /* eslint-disable no-underscore-dangle */
      db.collection('mitra').doc(this.id).get().then(async snapshot => {
        this.userData = snapshot.data()

        const options = {
          url: 'https://us-central1-mecare-life.cloudfunctions.net/getSaldoMitra',
          method: 'POST',
          data: {
            no_hp: snapshot.data().no_hp,
          },
        }
        await this.$axios(options).then(res => {
          this.saldo = parseInt(res.data)
        })

        this.swiperData = [
          { img: snapshot.data().fotoKTP, text: 'Foto KTP' },
          { img: snapshot.data().selfiKTP, text: 'Foto Selfi KTP' },
          { img: snapshot.data().suratPernyataan, text: 'Foto Surat Pernyataan' },
          { img: snapshot.data().selfiSuratPernyataan, text: 'Foto Selfi Surat Pernyataan' },
          { img: snapshot.data().fotoSKCK, text: 'Foto SKCK' },
          { img: snapshot.data().fotoProfil, text: 'Foto Profil' },
          { img: snapshot.data().sertifikat, text: 'Foto Sertifikat' },
        ]
        this.previewData = [
          snapshot.data().fotoKTP,
          snapshot.data().selfiKTP,
          snapshot.data().suratPernyataan,
          snapshot.data().selfiSuratPernyataan,
          snapshot.data().fotoSKCK,
          snapshot.data().fotoProfil,
          snapshot.data().sertifikat,
        ]
        this.loading = false

        if (snapshot.data().posisi != null && snapshot.data().posisi.geopoint != null) {
          this.mitraPosition = [
            snapshot.data().posisi.geopoint?._lat,
            snapshot.data().posisi.geopoint?._long,
            { draggable: 'true' },
          ]
          this.center = [
            snapshot.data().posisi.geopoint?._lat,
            snapshot.data().posisi.geopoint?._long,
            { draggable: 'false' },
          ]
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lokasi mitra tidak ditemukan',
              icon: 'MapIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    statusMitra() {
      if (this.userData.status_aktif === 1) return { variant: 'light-info', display: 'Sedang Diproses' }
      if (this.userData.status_aktif === 2) return { variant: 'light-info', display: 'Sedang Diverifikasi' }
      if (this.userData.status_aktif === 3) return { variant: 'light-danger', display: 'Ditolak' }
      if (this.userData.status_aktif === 4) return { variant: 'light-primary', display: 'Aktif' }
      if (this.userData.status_aktif === 5) return { variant: 'light-warning', display: 'Saldo Kurang' }
      if (this.userData.status_aktif === 6) return { variant: 'light-warning', display: 'Harus Konseling' }
      if (this.userData.status_aktif === 7) return { variant: 'light-warning', display: 'Perlu Retraining' }
      if (this.userData.status_aktif === 8) return { variant: 'light-danger', display: 'Dinonaktifkan' }
      if (this.userData.status_aktif === 9) return { variant: 'light-danger', display: 'Diblokir' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
    vertikalMitra(vertikal) {
      const vertikalVariant = {
        vl01: 'Akupunktur',
        vl02: 'Auto',
        vl03: 'Barber',
        vl04: 'Bekam',
        vl05: 'Beauty',
        vl06: 'Cleaning',
        vl07: 'Massage',
        vl08: 'Cuci AC',
      }
      return vertikalVariant[vertikal]
    },
    areaMitra(area) {
      const fromjson = regionJson.find(item => {
        return item.id === area
      })

      const region = fromjson.name.split(' ')

      region.shift()

      const kota = region.join(' ')

      return kota.toLowerCase()
    },
    aktifkanMitra() {
      this.$swal({
        title: 'Aktifkan mitra?',
        text: 'Konfirmasi jika anda ingin mengaktifkan mitra',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(this.id).set({
            status_aktif: 4,
            alasan_ditolak: firebase.firestore.FieldValue.delete(),
            terakhir_diedit: +new Date(),
          },
          {
            merge: true,
          })
            .then(() => {
            // eslint-disable-next-line newline-per-chained-call
              db.collection('appConfig').doc('web_hub').collection('blacklist').doc(this.id).delete()
              this.getMitra()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Mitra berhasil diaktifkan',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
        }
      })
    },
    async tolakMitra() {
      const alasan = await this.$swal({
        title: 'Beri alasan penolakan',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Konfirmasi',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      })
      if (alasan.value) {
        db.collection('mitra').doc(this.id).set({
          status_aktif: 3,
          alasan_ditolak: alasan.value,
          terakhir_diedit: +new Date(),
        }, { merge: true }).then(() => {
          this.getMitra()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Berhasil menolak mitra',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.value,
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
      }
    },
    async ubahNomorHp() {
      const nomor = await this.$swal({
        title: 'Input nomor HP yang baru',
        html: '<p>Format : 6281234123412</p',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Konfirmasi',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      })
      if (nomor.value) {
        if (this.id === nomor.value) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nomor baru tidak boleh sama dengan nomor lama',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$swal({
            title: 'Apakah anda yakin?',
            text: 'Data mitra akan dipindah dan akan disalin ke data yang baru sehingga beberapa data orderan akan hilang.',
            showCancelButton: true,
            confirmButtonText: 'Yakin',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value && this.id !== nomor.value) {
              const newData = this.userData
              newData.no_hp_lama = this.userData.no_hp
              newData.no_hp = nomor.value

              db.collection('mitra').doc(nomor.value).set(newData, { merge: true }).then(() => {
                db.collection('mitra').doc(this.id).collection('payment_log').get()
                  .then(paylog => {
                    if (!paylog.empty) {
                      paylog.docs.forEach(doc => {
                        db.collection('mitra').doc(nomor.value).collection('payment_log').add(doc.data())
                      })
                    }
                  })
                  .then(() => {
                    db.collection('mitra').doc(this.id).delete().then(() => {
                      this.$router.push({ name: 'DetailMitra', params: { id: nomor.value } })
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Nomor hp berhasil diubah',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                  })
              })
            }
          })
        }
      }
    },
    saldoKurang() {
      this.$swal({
        title: 'Yakin ingin blokir mitra ?',
        text: 'Alasan : Saldo kurang',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(this.id).set({
            status_aktif: 5,
            terakhir_diedit: +new Date(),
          }, { merge: true }).then(() => {
            // eslint-disable-next-line newline-per-chained-call
            db.collection('appConfig')
              .doc('web_hub')
              .collection('blacklist')
              .doc(this.id)
              .delete()
            this.getMitra()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil memblokir mitra',
                icon: 'TrashIcon',
                variant: 'Success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
    blokirMitra(status) {
      // console.log(this.keterangan)
      this.$swal({
        title: 'Yakin ingin blokir mitra?',
        text: `Alasan : ${status.label}`,
        inputPlaceholder: 'Masukan alasan mengapa diblokir',
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(this.id).set({
            status_aktif: parseInt(status.value),
            terakhir_diedit: +new Date(),
          }, { merge: true }).then(() => {
            db.collection('appConfig')
              .doc('web_hub')
              .collection('blacklist')
              .doc(this.id)
              .set({
                nik_ktp: this.userData.nik_ktp,
                status_aktif: parseInt(status.value),
                keterangan: result.value,
              }, { merge: true })
            this.getMitra()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil memblokir mitra',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
    hapusMitra() {
      this.$swal({
        title: 'Hapus data mitra?',
        text: 'Konfirmasi jika anda ingin menghapus data mitra',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(this.id).delete().then(() => {
            this.$router.push({ name: 'mitra' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data mitra',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
  .vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>

<style>
  .badge-mitra{
    margin: 2px;
  }

  .dokumen-card img{
    object-fit: cover;
  }

  .dropdown-blokir:hover{
    background-color: #ff9f43;
  }

  .dropdown-item:hover, .dropdown-item:focus{
    color: #fff;
  }
</style>
